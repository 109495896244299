import React from 'react'

const Logo = ( props ) => (
  <svg className={ props.logoClass } xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 21" aria-labelledby="filippoLogo" role="img">
    <title id="filippoLogo">Filippo Di Trapani</title>
      <g id="eyes">
        <path d="M.9,2.31a1,1,0,0,1,.31-.75A1,1,0,0,1,2,1.25a1,1,0,0,1,.75.32A1,1,0,0,1,3,2.31,1,1,0,0,1,2,3.38a1,1,0,0,1-.77-.3A1.06,1.06,0,0,1,.9,2.31Z"/><path d="M9.88,2.31a1,1,0,0,1,.31-.75,1,1,0,0,1,1.51,0,1,1,0,0,1,.31.75,1.09,1.09,0,0,1-1.83.77A1.09,1.09,0,0,1,9.88,2.31Z"/>
      </g>
      <path d="M10,16.58a.52.52,0,0,1,0,.14A3.72,3.72,0,0,1,9.2,18,3.62,3.62,0,0,1,8,18.79a3.79,3.79,0,0,1-1.55.31,3.79,3.79,0,0,1-1.55-.31A3.62,3.62,0,0,1,3.73,18,3.51,3.51,0,0,1,3,16.72a1,1,0,0,1,0-.14H1.43c.06.2.14.45.22.67a4.94,4.94,0,0,0,1.09,1.62,4.91,4.91,0,0,0,1.64,1.05,5.63,5.63,0,0,0,2.08.37,5.63,5.63,0,0,0,2.08-.37,4.74,4.74,0,0,0,1.64-1.05,4.87,4.87,0,0,0,1.09-1.62c.09-.22.16-.47.22-.67H10Z"/><path d="M1.23,5.73H2.69v8.74H1.23Z"/><path d="M5.72.35H7.18V14.48H5.72Z"/><path d="M10.22,5.73h1.46v8.74H10.22Z"/>
  </svg>
)

export default Logo
